import React, { useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "../css/productScroll.css";
import {
  Stack,
  Typography,
  Button,
  useMediaQuery,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DownloadRedesigned } from "./DownloadRedesigned";
import ImageCarousel from "./Carousel";

const AddOnsComponent = ({ title, heading, page, picture, images }) => {
  const [ref, inView] = useInView({
    threshold: 0.05,
    triggerOnce: false,
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
  };

  const previewDialog = () => {
    return (
      <Dialog
        sx={{ "& .MuiDialog-paper": { margin: "16px" } }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle fontSize={"16px"}>{title + " PDF Preview"} </DialogTitle>
        <DialogContent>
          <img
            src={picture}
            alt={title}
            style={{ maxHeight: "100%", maxWidth: "100%", padding: "8px" }}
          />
        </DialogContent>
      </Dialog>
    );
  };

  // return (
  //   <motion.div
  //     style={{"alignItems": "flex-start", padding: isSmallScreen? "20px":"40px", margin: isSmallScreen? "20px":"60px"}}
  //     ref={ref}
  //     variants={variants}
  //     initial="hidden"
  //     animate={inView ? "visible" : "hidden"}
  //     className="section-container"
  //   >
  //     <Stack direction={isSmallScreen ? "column" : "row"} gap={"36px"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
  //       <Stack direction={"column"} width={isSmallScreen ?"100%":"50%"} gap={"36px"}>
  //       <Typography variant={isSmallScreen?'h6':'h3'}>{title}</Typography>
  //       <Typography variant={isSmallScreen?'bod7':'body1'}>{heading}
  //     </Typography>

  //         {/* <motion.div
  //           variants={{
  //             hidden: { opacity: 0, x: -10 },
  //             visible: {
  //               opacity: 1,
  //               x: 0,
  //               transition: {
  //                 duration: 0.7,
  //               },
  //             },
  //           }}
  //         >
  //          <Button variant={isSmallScreen?"mobile":"contained"} onClick={()=>{navigate(page)}}>
  //             Try Now
  //             <ArrowForwardIcon sx={{ borderRadius: "12px", background: "white", fill: "#3567D6", width: "44px", height: "44px" }} />
  //           </Button>
  //         </motion.div> */}
  //     </Stack>
  //     <img style={{ width: isSmallScreen ? "80%" : "100%", height: isSmallScreen ? "80%" : "100%", maxHeight:'640px', maxWidth:'440px' }} src={`./${picture}`} alt="product pictures" />
  //     </Stack>
  //   </motion.div>
  // );

  return (
    <div
      style={{
        alignItems: "flex-start",
        padding: isSmallScreen ? "20px" : "40px",
        margin: isSmallScreen ? "20px" : "60px 60px 10px 60px",
      }}
      className="section-container"
    >
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        gap={"36px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Stack
          direction={"column"}
          width={isSmallScreen ? "100%" : "50%"}
          gap={"36px"}
        >
          <Typography variant={isSmallScreen ? "h6" : "h4"}>{title}</Typography>

          <Typography variant={isSmallScreen ? "bod7" : "body1"}>
            {heading}
          </Typography>
          <Stack direction="row" alignItems="center" gap={2}>
            <DownloadRedesigned sample={title} />
            <Tooltip placement="right" title={`Preview`}>
              <IconButton
                sx={{
                  border: "inset",
                  width: "44px",
                  height: "44px",
                }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                <VisibilityIcon fontSize="44px" />
              </IconButton>
            </Tooltip>
            {/* {previewDialog()} */}
            {images?.length ? (
              <ImageCarousel
                open={open}
                title={title}
                onClose={handleClose}
                images={images}
              />
            ) : (
              previewDialog()
            )}
          </Stack>
        </Stack>
        <img
          style={{
            width: isSmallScreen ? "80%" : "100%",
            height: isSmallScreen ? "80%" : "100%",
            maxHeight: "400px",
            maxWidth: "300px",
            objectFit: "contain",
          }}
          src={`./${picture}`}
          alt="product pictures"
        />
      </Stack>
    </div>
  );
};

export default AddOnsComponent;
