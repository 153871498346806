import { IconButton, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FaPlay, FaPause } from "react-icons/fa";

const useAudio = (url) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [audio, playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <div>
      <Tooltip placement='right' title={"Play Audio"}>
      <IconButton
        onClick={toggle}
        style={{
          backgroundColor: "transparent", 
          border: "none", 
          cursor: "pointer", 
          color: playing ? "#3567D6" : "gray", 
          transition: "color 0.2s ease-in-out", 
         
        }}
      >
        {playing ? <FaPause style={{ color: "#3567D6" }} /> : <FaPlay style={{ ":hover": { color: "#3567D6" }}}/>}
      </IconButton>
      </Tooltip>
    </div>
  );
};

export default Player;
