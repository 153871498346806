import React, { useState } from 'react';
import {
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
  } from '@mui/material';
  import ArrowBackIcon from '@mui/icons-material/ArrowBack';
  import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ImageCarousel = ({ images, open, onClose, title }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <Dialog sx={{ "& .MuiDialog-paper": { margin:"16px" } }} open={open} onClose={onClose} >
                <DialogTitle fontSize={"16px"} >{title + " PDF Preview"} </DialogTitle>
                <DialogContent  >
                
                
    <div className="carousel">
      <div  className="carousel-inner">
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
          >
           <img src={image.src} alt={image.alt} style={{
  maxWidth: '100%',
  maxHeight: '100%',
  padding:"8px",
}} />
          </div>
        ))}
      </div>
      
      
    </div>
    <IconButton style={{
  position: 'absolute',
  top: '50%',
  left: '10px',
  transform: 'translateY(-50%)',
  background:"rgb(209, 209, 209)",
  color: '#fff'
}} onClick={handlePrev}>
      <ArrowBackIcon  />
      </IconButton>
      <IconButton
      style={{
        position: 'absolute',
        top: '50%',
        right: '10px',
        transform: 'translateY(-50%)',
        background:"rgb(209, 209, 209)",
        color: '#fff',
      }}
      onClick={handleNext}>
      <ArrowForwardIcon />
      </IconButton>
    </DialogContent>
              </Dialog>
  );
};

export default ImageCarousel;
