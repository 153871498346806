import {
  Box,
  Button,
  Paper,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import FeaturesTabs from './Features';
import LogoCarousel from "./clientLogoCarousel";
import ScrollRevealComponent from "./Products";
import BiotechIcon from "@mui/icons-material/Biotech";
import Groups2Icon from "@mui/icons-material/Groups2";
import TranslateIcon from "@mui/icons-material/Translate";
import MeetingModal from "./MeetingModal";
import Slider from "react-slick";
import useGAEventsTracker from "./GAEventsHook";

export default function LandingPage() {
  const GAEventsTracker = useGAEventsTracker("Landing Page Activity");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showMeeting, setShowMeeting] = React.useState(false);
  const products = [
    {
      title: "Health Apps",
      heading: "to increase Patient Engagement",
      page: "/vizapp",
      picture: "products1.svg",
    },
    {
      title: "Gen AI Videos",
      heading: "to improve Health Education",
      page: "/personal",
      picture: "products2.svg",
    },
    {
      title: "Smart Reports",
      heading: "for both Patients and Doctors",
      page: "/smartReports",
      picture: "products3.svg",
    },
    // { title: 'Dashboards', heading: 'for insights and alalytics',  page: '/analytics',picture: 'products4.png' },
  ];

  const tooltipText = {
    biomarkers:
      "Across Biochemistry, Hematology, Clinical Pathology, Immunology & Serology",
    users: "Spread over India, UAE, UK, Botswana & Dominica",
    languages: "In English, Arabic & Hindi (With More to Come)",
  };

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 14000,
    autoplaySpeed: 500,
    cssEase: "linear",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          width: "100%",
          height: isSmallScreen ? "auto" : "auto",
        },
      }}
    >
      <Paper elevation={0}>
        <Stack
          style={{ background: "#E9F5FE", minHeight: "100vh" }}
          paddingX={isSmallScreen ? "20px" : "60px"}
          paddingBottom={"40px"}
          paddingTop={"96px"}
          direction={isSmallScreen ? "column" : "row"}
          justifyContent={"space-between"}
          alignItems={isSmallScreen ? "center" : "flex-start"}
          gap={"16px"}
        >
          <Stack width={isSmallScreen ? "100%" : "60%"} gap={"44px"}>
            <Typography variant={isSmallScreen ? "h4" : "h1"}>
              Your Patients want{" "}
              <Typography variant={isSmallScreen ? "h5" : "h2"}>
                Clarity
              </Typography>
              , not Reports
            </Typography>
            <Stack width={"90%"} gap={"25px"}>
              <Typography variant={isSmallScreen ? "body7" : "body1"}>
                Welcome to the future of modern diagnostics. Our thoughtfully
                designed products are scientifically vetted and bring{" "}
                <Typography
                  variant={isSmallScreen ? "body4" : "body1"}
                  fontWeight={700}
                >
                  Smart Healthcare for Providers.
                </Typography>
              </Typography>
              <Typography variant={isSmallScreen ? "body7" : "body1"}>
                Commoditized checkup services lead to low retention rates. By
                improving Patient Engagement with our smart products, you
                improve both your customer footfall and marketing traction [labs
                have reported 10-30% increase in B2C revenues and customer
                satisfaction scores].
              </Typography>
            </Stack>
            <Button
              onClick={() => {
                GAEventsTracker("Demo Booked");
                setShowMeeting(true);
              }}
              variant={isSmallScreen ? "mobile" : "contained"}
            >
              Book Demo
              <ArrowForwardIcon
                sx={{
                  borderRadius: "12px",
                  background: "white",
                  fill: "#3567D6",
                  width: "44px",
                  height: "44px",
                }}
              />
            </Button>
            <MeetingModal
              showMeeting={showMeeting}
              setShowMeeting={setShowMeeting}
            />

            <Stack direction={"row"} gap={"16px"}>
              <Tooltip
                disableFocusListener
                enterTouchDelay={10}
                title={tooltipText.biomarkers}
                placement="bottom-start"
              >
                <Paper
                  elevation={0}
                  sx={{
                    width: "fit-content",
                    padding: "4px 16px 4px 4px",
                    alignItems: "center",
                    borderRadius: "8px",
                    background: "#FFF",
                    transition: "background-color 0.3s ease-in-out",
                    cursor: "pointer",
                    ":hover": {
                      background: "#6c757d30",
                    },
                    boxShadow:
                      " 0px 6.706px 117.362px 0px rgba(46, 52, 54, 0.14), 0px 6.706px 19.56px 0px rgba(46, 52, 54, 0.14)",
                  }}
                >
                  <BiotechIcon
                    style={{
                      borderRadius: "8px",
                      fontSize: "44px",
                      background: "#D9EAF3",
                      fill: "#3567D6",
                      marginRight: "10px",
                    }}
                  />

                  <Typography
                    fontWeight={600}
                    variant={isSmallScreen ? "body11" : "body5"}
                  >
                    300+{" "}
                    <Typography variant={isSmallScreen ? "body11" : "body5"}>
                      {" Biomarkers"}
                    </Typography>
                  </Typography>
                </Paper>
              </Tooltip>
              <Tooltip
                disableFocusListener
                enterTouchDelay={10}
                title={tooltipText.users}
                placement={isMobile ? "bottom" : "bottom-start"}
              >
                <Paper
                  elevation={0}
                  sx={{
                    width: "fit-content",
                    padding: "4px 16px 4px 4px",
                    alignItems: "center",
                    borderRadius: "8px",
                    background: "#FFF",
                    transition: "background-color 0.3s ease-in-out",
                    cursor: "pointer",
                    ":hover": {
                      background: "#6c757d30",
                    },
                    boxShadow:
                      " 0px 6.706px 117.362px 0px rgba(46, 52, 54, 0.14), 0px 6.706px 19.56px 0px rgba(46, 52, 54, 0.14)",
                  }}
                >
                  <Groups2Icon
                    style={{
                      borderRadius: "8px",
                      fontSize: "44px",
                      background: "#D9EAF3",
                      fill: "#3567D6",
                      marginRight: "10px",
                    }}
                  />
                  <Typography
                    fontWeight={600}
                    variant={isSmallScreen ? "body11" : "body5"}
                  >
                    5 Million{" "}
                    <Typography variant={isSmallScreen ? "body11" : "body5"}>
                      {" Users"}
                    </Typography>
                  </Typography>
                </Paper>
              </Tooltip>
              <Tooltip
                disableFocusListener
                enterTouchDelay={10}
                title={tooltipText.languages}
                placement="bottom-start"
              >
                <Paper
                  elevation={0}
                  sx={{
                    width: "fit-content",
                    padding: "4px 16px 4px 4px",
                    alignItems: "center",
                    borderRadius: "8px",
                    background: "#FFF",
                    transition: "background-color 0.3s ease-in-out",
                    cursor: "pointer",
                    ":hover": {
                      background: "#6c757d30",
                    },
                    boxShadow:
                      " 0px 6.706px 117.362px 0px rgba(46, 52, 54, 0.14), 0px 6.706px 19.56px 0px rgba(46, 52, 54, 0.14)",
                  }}
                >
                  <TranslateIcon
                    style={{
                      borderRadius: "8px",
                      fontSize: "44px",
                      background: "#D9EAF3",
                      fill: "#3567D6",
                      marginRight: "10px",
                    }}
                  />
                  <Typography
                    fontWeight={600}
                    variant={isSmallScreen ? "body11" : "body5"}
                  >
                    3{" "}
                    <Typography variant={isSmallScreen ? "body11" : "body5"}>
                      {" Languages"}
                    </Typography>
                  </Typography>
                </Paper>
              </Tooltip>
            </Stack>
          </Stack>
          <Stack width={isSmallScreen ? "100%" : "40%"}>
            <img
              style={{
                width: isSmallScreen ? "auto" : "100%",
                height: isSmallScreen ? "auto" : "100%",
                maxHeight: "540px",
                maxWidth: "540px",
              }}
              src="landing.png"
              alt="Landing Page"
            />
          </Stack>
        </Stack>
        <Stack style={{ background: "white" }}>
          <LogoCarousel />
        </Stack>
        {/* <Stack>
          {products.map((product, index) => (
            <ScrollRevealComponent
              key={index}
              title={product.title}
              heading={product.heading}
              page={product.page}
              picture={product.picture}
            />
          ))}
        </Stack> */}
        <Box>
          <Slider {...settings}>
            {products.map((product, index) => (
              <ScrollRevealComponent
                key={index}
                title={product.title}
                heading={product.heading}
                page={product.page}
                picture={product.picture}
              />
            ))}
          </Slider>
        </Box>
        {/* <FeaturesTabs /> */}
      </Paper>
    </Box>
  );
}
