import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Stack,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip,
} from "@mui/material";
import Player from "./AudioRedesigned";
import { DownloadRedesigned } from "./DownloadRedesigned";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ImageCarousel from "./Carousel";
import useGAEventsTracker from "./GAEventsHook";

export default function ProductCarousel({ slides }) {
  const GAEventsTracker = useGAEventsTracker("Activity on Brochure");
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    console.log("newValue", newValue);
    setSelectedTab(newValue);
  };
  return (
    <Paper
      elevation={0}
      style={{ background: "#fff", height: isSmallScreen ? "" : "90vh" }}
    >
      <Stack
        paddingX={isSmallScreen ? "20px" : "60px"}
        paddingBottom={"20px"}
        direction={isSmallScreen ? "row" : "row"}
        justifyContent={isSmallScreen ? "space-between" : "flex-start"}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="Basic Tabs"
              width={"100%"}
              variant="fullWidth"
            >
              {slides.map((slide, index) => (
                <Tab
                  key={index}
                  label={slide.title}
                  sx={{
                    fontSize: { sm: "18px", xs: "14px" },
                    fontWeight: "600",
                  }}
                />
              ))}
            </Tabs>
          </Box>
          {slides[selectedTab] && (
            <Box marginTop={"24px"}>
              <Stack
                direction={isSmallScreen ? "column" : "row"}
                alignItems={isSmallScreen ? "flex-start" : "flex-start"}
                gap={"48px"}
              >
                <Stack gap={"48px"} width={isSmallScreen ? "100%" : "60%"}>
                  <Stack direction="row" gap={"16px"} alignItems={"center"}>
                    <Typography variant={isSmallScreen ? "h6" : "h3"}>
                      {slides[selectedTab].heading}
                    </Typography>
                    {/* {slides[selectedTab].audio && <Player url={slides[selectedTab].audio}/>} */}
                  </Stack>
                  <Stack width={"90%"} gap={"24px"}>
                    <Typography variant={isSmallScreen ? "body7" : "body1"}>
                      {slides[selectedTab].description}
                    </Typography>
                  </Stack>
                  {slides[selectedTab].sample && (
                    <Stack direction={"row"} gap="16px" alignItems={"center"}>
                      <DownloadRedesigned sample={slides[selectedTab].sample} />
                      <Tooltip placement="right" title={`Preview`}>
                        <IconButton
                          sx={{
                            border: "inset",
                            width: "44px",
                            height: "44px",
                          }}
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          <VisibilityIcon fontSize="44px" />
                        </IconButton>
                      </Tooltip>
                      <ImageCarousel
                        open={open}
                        title={slides[selectedTab].title}
                        onClose={handleClose}
                        images={slides[selectedTab].images}
                      />
                    </Stack>
                  )}
                </Stack>
                <Stack width={isSmallScreen ? "40vh" : "35%"}>
                  {slides[selectedTab].title === "Smart Video Report" ||
                  slides[selectedTab].title === "Video Report" ? (
                    <video
                      controls
                      width={"100%"}
                      style={{ maxWidth: "440px", maxHeight: "440px" }}
                      loop
                    >
                      <source
                        src={
                          "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-images/svr.mp4"
                        }
                      />
                    </video>
                  ) : (
                    <img
                      style={{ width: "auto", height: "auto" }}
                      src={slides[selectedTab].illustration}
                      alt={slides[selectedTab].title}
                    />
                  )}
                </Stack>
              </Stack>
            </Box>
          )}
        </Box>
      </Stack>
    </Paper>
  );
}
