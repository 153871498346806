import {
  Box,
  Button,
  Paper,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ProductCarousel from "./ProductCarouselComponent";
import MeetingModal from "./MeetingModal";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import ShieldIcon from "@mui/icons-material/Shield";
import Player from "./AudioRedesigned";
import useGAEventsTracker from "./GAEventsHook";

export default function VizAppPage() {
  const GAEventsTracker = useGAEventsTracker("Vizapp Activity");
  const [showMeeting, setShowMeeting] = React.useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const slides = [
    {
      title: isSmallScreen ? "Coverage" : "Complete Report Coverage",
      heading: "Complete Report Coverage",
      description:
        "Almost all biomarkers within hematology and biochemistry are covered. We are open to adding more parameters.",
      illustration: "vizapp4.png",
      audio: "",
      sample: "",
    },
    {
      title: isSmallScreen ? "Interactive" : "Interactive & Searchable",
      heading: "Interactive & Searchable",
      description:
        "Users can click on the human body, navigation carousel & search bar to quickly check the profile of their choice. There's also a toggle button to change the view to 'abnormal only' results.",
      illustration: "vizapp3.png",
      audio: "",
      sample: "",
    },
    {
      title: isSmallScreen ? "Media" : "Shop & Media Sections",
      heading: "Shop & Media Sections",
      description:
        "Users can learn more about concerning test profiles through curated articles & videos. Our smart recommender suggests the top products & services that they should avail to keep track of their health.",
      illustration: "vizapp2.png",
      audio: "",
      sample: "",
    },
    {
      title: isSmallScreen ? "Analytics" : "User Analytics",
      heading: "User Analytics",
      description:
        "Detailed user analytics & recommendations around product use as well as risk profiles will be provided to you on a monthly basis, to help you target your interested users better.",
      illustration: "vizapp1.svg",
      audio: "",
      sample: "",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",

        "& > :not(style)": {
          width: "100%",
        },
      }}
    >
      <Paper elevation={0}>
        <Stack
          style={{
            background: "#E9F5FE",
            alignItems: "center",
            height: isSmallScreen ? "auto" : "100vh",
          }}
          paddingX={isSmallScreen ? "20px" : "60px"}
          marginBottom={"60px"}
          paddingTop={"120px"}
          direction={isSmallScreen ? "column" : "row"}
          justifyContent={"space-between"}
          alignItems={isSmallScreen ? "flex-start" : "flex-start"}
          gap={"48px"}
        >
          <Stack width={isSmallScreen ? "100%" : "60%"} gap={"48px"}>
            <Stack direction="row" gap={"16px"} alignItems={"center"}>
              <Typography variant={isSmallScreen ? "h6" : "h3"}>
                Viz App
              </Typography>
              <Player
                url={
                  "https://dn96iqv3kf32j.cloudfront.net/brochure-images/audios/digitalSRaudio.mp3"
                }
              />
            </Stack>
            <Stack width={"90%"} gap={"10px"}>
              <Typography variant={isSmallScreen ? "body7" : "body1"}>
                Web-based Smart Report application with world-class UI/UX and
                features. Instant, secure and mobile-friendly, it’s a product
                for the 21st century.
              </Typography>
            </Stack>
            <Button
              onClick={() => {
                GAEventsTracker("Viapp opened", "successfully");
                // window.open(
                //   "https://master.d2psssbi1dbgfm.amplifyapp.com/overview?sample"
                // );
                window.open(
                  "https://www.demo-vizapp.niroggyan.com/overview?id=5a5e63561560607acca2c7eee9d6fb913e48b7be5da3c45feee92b7faf8f77d1"
                );
              }}
              variant={isSmallScreen ? "mobile" : "contained"}
            >
              Try Now
              <ArrowForwardIcon
                sx={{
                  borderRadius: "12px",
                  background: "white",
                  fill: "#3567D6",
                  width: "44px",
                  height: "44px",
                }}
              />
            </Button>
            <Stack direction={"row"} gap={"16px"}>
              <Paper
                elevation={0}
                sx={{
                  width: "fit-content",
                  padding: "4px 16px 4px 4px",
                  alignItems: "center",
                  borderRadius: "8px",
                  background: "#FFF",
                  transition: "background-color 0.3s ease-in-out",
                  ":hover": {
                    background: "#6c757d30",
                  },
                  boxShadow:
                    " 0px 6.706px 117.362px 0px rgba(46, 52, 54, 0.14), 0px 6.706px 19.56px 0px rgba(46, 52, 54, 0.14)",
                }}
              >
                <MobileFriendlyIcon
                  style={{
                    borderRadius: "8px",
                    fontSize: "44px",
                    background: "#D9EAF3",
                    fill: "#3567D6",
                    marginRight: "10px",
                  }}
                />
                <Typography
                  fontWeight={600}
                  variant={isSmallScreen ? "body11" : "body5"}
                >
                  Mobile-friendly{" "}
                </Typography>
              </Paper>
              <Paper
                elevation={0}
                sx={{
                  width: "fit-content",
                  padding: "4px 16px 4px 4px",
                  alignItems: "center",
                  borderRadius: "8px",
                  background: "#FFF",
                  transition: "background-color 0.3s ease-in-out",
                  ":hover": {
                    background: "#6c757d30",
                  },
                  boxShadow:
                    " 0px 6.706px 117.362px 0px rgba(46, 52, 54, 0.14), 0px 6.706px 19.56px 0px rgba(46, 52, 54, 0.14)",
                }}
              >
                <ShuffleIcon
                  style={{
                    borderRadius: "8px",
                    fontSize: "44px",
                    background: "#D9EAF3",
                    fill: "#3567D6",
                    marginRight: "10px",
                  }}
                />
                <Typography
                  fontWeight={600}
                  variant={isSmallScreen ? "body11" : "body5"}
                >
                  Flexible Design{" "}
                </Typography>
              </Paper>
              <Paper
                elevation={0}
                sx={{
                  width: "fit-content",
                  padding: "4px 16px 4px 4px",
                  alignItems: "center",
                  borderRadius: "8px",
                  background: "#FFF",
                  transition: "background-color 0.3s ease-in-out",
                  ":hover": {
                    background: "#6c757d30",
                  },
                  boxShadow:
                    " 0px 6.706px 117.362px 0px rgba(46, 52, 54, 0.14), 0px 6.706px 19.56px 0px rgba(46, 52, 54, 0.14)",
                }}
              >
                <ShieldIcon
                  style={{
                    borderRadius: "8px",
                    fontSize: "44px",
                    background: "#D9EAF3",
                    fill: "#3567D6",
                    marginRight: "10px",
                  }}
                />
                <Typography
                  fontWeight={600}
                  variant={isSmallScreen ? "body11" : "body5"}
                >
                  Fully Secure{" "}
                </Typography>
              </Paper>
            </Stack>
            <MeetingModal
              showMeeting={showMeeting}
              setShowMeeting={setShowMeeting}
            />
          </Stack>
          <Stack width={isSmallScreen ? "40vh" : "35%"}>
            <img
              style={{
                width: isSmallScreen ? "auto" : "100%",
                height: isSmallScreen ? "auto" : "100%",
              }}
              src="vizapp.png"
              alt="vizapp Page"
            />
          </Stack>
        </Stack>
        <ProductCarousel slides={slides} />
      </Paper>
    </Box>
  );
}
