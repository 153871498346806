import { Box, Button, Paper, Typography, Stack, useTheme, useMediaQuery } from '@mui/material';
import * as React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ProductCarousel from './ProductCarouselComponent';
import MeetingModal from './MeetingModal';

const slides = [
  {
    title: 'Personal Report',
    heading:'Personal Report',
    description: 'Detailed analysis of your individual test results.',
    illustration: "Smart_report.svg",
    audio:"",
    sample:""
  },
  {
    title: 'Total Report',
    heading:'Total Report',
    description: 'Compare your results to population averages and benchmarks.',
    illustration: "Smart_report.svg",
    audio:"",
    sample:""
  },
  {
    title: 'Trend Report',
    heading:'Trend Report',
    description: 'Track your health over time and identify trends.',
    illustration: "Smart_report.svg",
    audio:"",
    sample:""
  },
  {
    title: 'Compact Report',
    heading:'Compact Report',
    description: 'Quick and concise summary of your key findings.',
    illustration: "Smart_report.svg",
    audio:"",
    sample:""
  },
];

export default function AnalyticsPage() {
  const [showMeeting, setShowMeeting] = React.useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

 
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          width: "100%",
          height: '100vh',
        },
        background: '#E9F5FE'
      }}
    >
      <Paper elevation={0} style={{ background: '#E9F5FE' }}>
        <Stack
          style={{ background: '#E9F5FE' }}
          paddingX={isSmallScreen ? "20px" : "60px"}
          marginBottom={"150px"}
          paddingTop={"120px"}
          direction={isSmallScreen ? "column" : "row"}
          justifyContent={"space-between"}
          alignItems={isSmallScreen ? "flex-start" : "flex-start"}
          gap={"16px"}
        >
          <Stack width={isSmallScreen ? "100%" : "60%"} gap={"25px"}>
            <Typography variant={'h3'}>Smart Reports</Typography>
            <Stack width={"90%"} gap={"25px"}>
              <Typography variant='body1'>Smart Health Reports simplify medical test results with clear explanations, visualizations, and actionable insights</Typography>
            </Stack>
            <Button onClick={() => {
                  setShowMeeting(true);
                }} variant={isSmallScreen?"mobile":"contained"}>
              Book Demo
              <ArrowForwardIcon sx={{ borderRadius: "12px", background: "white", fill: "#3567D6", width: "44px", height: "44px" }} />
            </Button>
            <MeetingModal showMeeting={showMeeting} setShowMeeting={setShowMeeting}/>
          </Stack>
          <Stack width={isSmallScreen ? "40vh" : "35%"}>
            <img style={{ width: isSmallScreen ? "auto" : "100%", height: isSmallScreen ? "auto" : "100%" }} src="Smart_report.svg" alt="Smart Report Page" />
          </Stack>
        </Stack>
        <ProductCarousel slides={slides}/>
      </Paper>
    </Box>
  );
}
