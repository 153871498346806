import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "../css/productScroll.css";
import { Stack, Typography, Button, useMediaQuery } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import useGAEventsTracker from "./GAEventsHook";
import { Link } from "react-router-dom";

const ScrollRevealComponent = ({ title, heading, page, picture }) => {
  const [ref, inView] = useInView({
    threshold: 0.05,
    triggerOnce: false,
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const GAEventsTracker = useGAEventsTracker("Landing Page Activity");

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
  };

  // return (
  //   <motion.div
  //     style={{
  //       alignItems: "flex-start",
  //       padding: isSmallScreen ? "20px" : "40px",
  //       margin: isSmallScreen ? "20px" : "60px",
  //     }}
  //     ref={ref}
  //     variants={variants}
  //     initial="hidden"
  //     animate={inView ? "visible" : "hidden"}
  //     className="section-container"
  //   >
  //     <Stack
  //       direction={isSmallScreen ? "column" : "row"}
  //       gap={"36px"}
  //       justifyContent={"space-between"}
  //       alignItems={"center"}
  //       width={"100%"}
  //     >
  //       <Stack
  //         direction={"column"}
  //         width={isSmallScreen ? "100%" : "50%"}
  //         gap={"36px"}
  //       >
  //         <Typography variant={isSmallScreen ? "h4" : "h1"}>
  //           <Typography variant={isSmallScreen ? "h5" : "h2"}>
  //             {title}
  //           </Typography>
  //           {" " + heading}
  //         </Typography>

  //         <motion.div
  //           variants={{
  //             hidden: { opacity: 0, x: -10 },
  //             visible: {
  //               opacity: 1,
  //               x: 0,
  //               transition: {
  //                 duration: 0.7,
  //               },
  //             },
  //           }}
  //         >
  //           <Button
  //             variant={isSmallScreen ? "mobile" : "contained"}
  //             onClick={() => {
  //               GAEventsTracker("naviagted to", page);
  //               navigate(page);
  //             }}
  //           >
  //             Try Now
  //             <ArrowForwardIcon
  //               sx={{
  //                 borderRadius: "12px",
  //                 background: "white",
  //                 fill: "#3567D6",
  //                 width: "44px",
  //                 height: "44px",
  //               }}
  //             />
  //           </Button>
  //         </motion.div>
  //       </Stack>
  //       <img
  //         style={{
  //           width: isSmallScreen ? "80%" : "100%",
  //           height: isSmallScreen ? "80%" : "100%",
  //           maxHeight: "440px",
  //           maxWidth: "440px",
  //         }}
  //         src={`./${picture}`}
  //         alt="product pictures"
  //       />
  //     </Stack>
  //   </motion.div>
  // );

  return (
    <div
      style={{
        alignItems: "flex-start",
        padding: isSmallScreen ? "20px" : "0px 40px",
        margin: isSmallScreen ? "20px" : "60px 60px 10px 60px",
      }}
      className="section-container"
    >
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        gap={"36px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Stack
          direction={"column"}
          width={isSmallScreen ? "100%" : "50%"}
          gap={"36px"}
        >
          <Typography variant={isSmallScreen ? "h4" : "h1"}>
            <Typography variant={isSmallScreen ? "h5" : "h2"}>
              {title}
            </Typography>
            {" " + heading}
          </Typography>

          <div>
            <Button
              variant={isSmallScreen ? "mobile" : "contained"}
              onClick={() => {
                GAEventsTracker("naviagted to", page);
                window.scrollTo(0, 0);
                navigate(page);
              }}
            >
              Try Now
              <ArrowForwardIcon
                sx={{
                  borderRadius: "12px",
                  background: "white",
                  fill: "#3567D6",
                  width: "44px",
                  height: "44px",
                }}
              />
            </Button>
          </div>
        </Stack>
        <img
          style={{
            width: isSmallScreen ? "80%" : "100%",
            height: isSmallScreen ? "80%" : "100%",
            maxHeight: "440px",
            maxWidth: "440px",
          }}
          src={`./${picture}`}
          alt="product pictures"
        />
      </Stack>
    </div>
  );
};

export default ScrollRevealComponent;
