import React from 'react';
import Slider from 'react-slick';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';

const clientsArray = [
  'https://dn96iqv3kf32j.cloudfront.net/new-company-website-files/our_clients/svgs/srl-updated.svg',
  'https://niroggyan.s3.ap-south-1.amazonaws.com/new-company-website-files/our_clients/svgs/max-updated.svg',
  'https://niroggyan.s3.ap-south-1.amazonaws.com/new-company-website-files/our_clients/svgs/redcliffe-logo-1.svg',
  'https://niroggyan.s3.ap-south-1.amazonaws.com/new-company-website-files/our_clients/svgs/dangs-updates.svg',
  'https://niroggyan.s3.ap-south-1.amazonaws.com/new-company-website-files/our_clients/svgs/pharmeasy-updated.svg',
  'https://niroggyan.s3.ap-south-1.amazonaws.com/new-company-website-files/our_clients/svgs/arab-updated.svg',
  'https://niroggyan.s3.ap-south-1.amazonaws.com/new-company-website-files/our_clients/svgs/1mg-1.svg',
  'https://niroggyan.s3.ap-south-1.amazonaws.com/new-company-website-files/our_clients/svgs/Blal-logo-1.svg',
  'https://niroggyan.s3.ap-south-1.amazonaws.com/new-company-website-files/our_clients/svgs/proactive-logo-1.svg',
  'https://niroggyan.s3.ap-south-1.amazonaws.com/new-company-website-files/our_clients/svgs/logo-neuberg.png',
];

const LogoCarousel = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: isSmallScreen? 3 :6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
  };

  return (
    <div>
      <Slider {...settings}>
        {clientsArray.map((logo, index) => (
          <div key={index}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px',
                paddingTop: isSmallScreen ? '20px': '60px',
                width: isSmallScreen? '100px': '170px',
                minHeight: isSmallScreen? '40px': '67px',

              }}
            >
              <img style={{width:isSmallScreen?'80px':'150px'}} src={logo} alt={index} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default LogoCarousel;