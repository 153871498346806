import React from "react";
import { Routes, Route } from "react-router-dom";
import "./css/App.css";
import Navbar from "./components/NavBarRedesigned";
import LandingPage from "./components/LandingPageRedesigned";
import SmartReportPage from "./components/SmartReportsRedesigned";
import VizAppPage from "./components/VizappRedesigned";
import AnalyticsPage from "./components/AnalyticsRedesigned";
import PersonalProductsPage from "./components/PersonalProductsRedesigned";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="font">
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/smartReports" element={<SmartReportPage />} />
        <Route path="/vizapp" element={<VizAppPage />} />
        <Route path="/analytics" element={<AnalyticsPage />} />
        <Route path="/personal" element={<PersonalProductsPage />} />
      </Routes>
    </div>
  );
}

export default App;
